import * as React from 'react';

// types
import type { PropsWithChildren } from 'react';

// components
import { Text } from '@mantine/core';

const ErrorMessage = ({ children }: PropsWithChildren) => {
    return (
        <Text
            size='lg'
            color='dark.1'
            sx={(theme) => ({
                margin: 'auto',
                marginTop: theme.spacing.sm,
                marginBottom: theme.spacing.xl * 1.5,
            })}>
            {children}
        </Text>
    );
};

export default ErrorMessage;