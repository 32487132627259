import * as React from 'react';
import ErrorCode from './error-code';
import ErrorTitle from './error-title';
import ErrorMessage from './error-message';
import { Button, Stack } from '@mantine/core';
import { Link } from 'gatsby';

interface Props {
    code: number;
    title: string;
    message?: string;
};

const ErrorSection = ({ code, title, message }: Props) => {
    const messageText = message ? message : `Er is een fout opgetreden met code ${code}`;

    return (
        <Stack align='center' py={80} sx={(theme) => ({
            backgroundColor: theme.colors.dark[5],
        })}>
            <ErrorCode>{code}</ErrorCode>
            <ErrorTitle>{title}</ErrorTitle>
            <ErrorMessage>{messageText}</ErrorMessage>
            <Button component={Link} to='/'>
                Terug naar de homepage
            </Button>
        </Stack>
    );
};

export default ErrorSection;
