import * as React from 'react';
import ErrorSection from '../components/error/error-section';

// components
import Layout from '../components/layout';
import SEO from '../components/seo';

const title = 'Pagina niet gevonden';

const NotFoundPage = () => {
    return (
        <Layout>
            <ErrorSection code={404} title={title} message='De pagina op deze URL bestaat niet.'/>
        </Layout>
    );
};

export const Head = () => (
    <SEO title={title}/>
);

export default NotFoundPage;