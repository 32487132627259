import * as React from 'react';

// types
import type { PropsWithChildren } from 'react';

// components
import { Text, Title } from '@mantine/core';

const ErrorCode = ({ children }: PropsWithChildren) => {
    return (
        <Title order={1}>
            <Text 
                weight={900}
                size={220}
                color='dark.3'
                sx={(theme) => ({
                    lineHeight: 1,
                    marginBottom: theme.spacing.xl,
                    
                    [theme.fn.smallerThan('sm')]: {
                        fontSize: 120,
                      },
                })}>
                {children}
            </Text>
        </Title>
    );
};

export default ErrorCode;