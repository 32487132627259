import * as React from 'react';

// types
import type { PropsWithChildren } from 'react';

// components
import { Text, Title } from '@mantine/core';

const ErrorTitle = ({ children }: PropsWithChildren) => {
    return (
        <Title order={2}>
            <Text
                weight={800}
                size={38}
                sx={(theme) => ({
                    color: theme.colors.gray[0],
                    [theme.fn.smallerThan('sm')]: {
                        fontSize: 32,
                    }
                })}>
                {children}
            </Text>
        </Title>
    );
};

export default ErrorTitle;